<template>
  <div class="row">

    <div class="col-md-8">
      <Print
        :data="data"
        height="800px"
        :setting="setting"
        :qrcodeValue="qrcodeValue"
      />
    </div>

    <!-- PDF -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :filename="`Surat Keterangan Kelahiran`"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      @hasDownloaded="hasDownloaded($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <Print
          :data="data"
          height="470px"
          :setting="setting"
          :qrcodeValue="qrcodeValue"
        />
      </section>
    </vue-html2pdf>

    <div class="col-md">
      <b-button v-if="data.letter_status_id == 7 || data.letter_status_id == 4 || data.letter_status_id == 5"
        squared
        class="mt-2"
        size="sm"
        variant="primary"
        @click="btnPrintOnClick"
      >Print</b-button>
      <b-button v-if="data.letter_status_id == 7"
        squared
        :class="'d-block mt-2'"
        size="sm"
        variant="info"
        @click="btnUploadOnClick"
        >Upload</b-button
      >
      <b-button
        squared
        :class="'d-block mt-2'"
        size="sm"
        variant="info"
        v-if="data.pdf_url != ''"
        @click="btnSendOnClick"
        >Kirim</b-button
      >
      <b-button
        squared
        :class="'d-block mt-2'"
        size="sm"
        variant="success"
        @click="$router.push('/birth-certificate/edit/' + data.id)"
      >Edit Surat</b-button>
      <b-button
        squared
        :class="'d-block mt-2'"
        size="sm"
        variant="success"
        @click="$router.push('/birth-certificate/add')"
      >Tambah Baru</b-button>
    </div>

  </div>
</template>

<script>

import Print from '@/view/components/birth-certificate/Print.vue'
import module from '@/core/modules/CrudModule.js'
import VueHtml2pdf from "@/view/components/general/VueHtml2Pdf.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Print,
    VueHtml2pdf
  },

  data() {
    return {
      // Data
      data: {},
      setting: {},
      manipulateBtn: true,
      form: {
        letter_id: "",
        letter_type_id: "",
        citizen_id: "",
        pdf_url: "",
        pdf_name: "",
      },
      qrcodeValue: "",
    }
  },

  methods: {

    async getSetting() {
      this.setting = await module.get(`settings/${1}`)
    },

    async get() {
      this.data = await module.get(`birth-certificate/${this.$route.params.id}`)
      // If Data Not found
      if (this.data == null) {
        // Redirect To List
        this.$router.push('birth-certificate/list')
      }else{
        this.qrcodeValue = "https://is3.cloudhost.id/wellmagic-dev-bucket/kemendes/letters/"+this.data.citizen_id_card_number+"/"+this.data.letter_type_id+"/"+this.data.title.replace(/\s+/g, '')+".pdf"
      }
    },

    async btnSendOnClick(){
      console.log('route', `letters/${this.data.letter_id}/send`)
      let formData = new FormData();
      let response = await module.submit(
        formData,
        `letters/${this.data.letter_id}/send`
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        Swal.fire({
          title: "Gagal Kirim file",
          text: "",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.get()
      }
    },

    onProgress($event) {
      console.log("onProgress", $event)
    },

    hasStartedGeneration() {
      console.log("hasStartedGeneration")
    },

    hasGenerated($event) {
      console.log("hasGenerated", $event)
    },

    btnPrintOnClick() {
      this.$htmlToPaper('print-lab-report')
    },

    async hasDownloaded($event) {
      console.log("hasDownloaded", $event);
      this.form.letter_id = this.data.letter_id;
      this.form.letter_type_id = this.data.letter_type_id;
      this.form.citizen_id = this.data.citizen_id;
      this.form.pdf_url = $event;
      this.form.pdf_name = this.data.title.replace(/\s+/g, '')

      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(
        formData,
        `letters/${this.form.letter_id}/upload`
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        Swal.fire({
          title: "Gagal upload file",
          text: "",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.get()
      }
    },

    btnUploadOnClick() {
      this.$refs.html2Pdf.generatePdf()
    },

    // access management
    async setActiveMenu() {

      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for (a = 0; a < access_right.length; a++) {
        console.log("looping")

        if (access_right[a] == "1102") {
          this.manipulateBtn = true
        }

      }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Surat Menyurat", route: "/letters" },
      { title: "Keterangan Kelahiran", route: "/birth-certificate" },
      { title: "Detail"},
    ]);
    // Get Data
    this.get()
    this.getSetting()
    this.setActiveMenu()
  },

}
</script>

<style>
</style>